<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body" :style="intraction">
        <MovedDialog
          v-if="movedDialog"
          :callbackClose="() => (movedDialog = false)"
          :showDialog="true"
          :message="movedMsg"
          :image="movedRefImg"
        />
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("title.town") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="pa-5">
            <v-row>
              <v-col
                cols="4"
                v-for="(item, i) in items"
                :key="i"
                style="position: relative"
              >
                <v-img
                  v-if="!item.isLock || (item.isLock && settings.debug)"
                  :id="item.tutId"
                  :src="item.icon"
                  width="150"
                  class="d-flex justify-center align-end text-center text-stroke-sm text-h6 menu-item"
                  @click="
                    () => {
                      $intro().exit();
                      $router.push({
                        name: item.page,
                      });
                    }
                  "
                >
                  {{ item.name }}
                </v-img>
                <div
                  v-else
                  style="aspect-ratio: 1"
                  class="d-flex align-center justify-center"
                >
                  <v-icon color="cyan lighten-2">mdi-circle</v-icon>
                </div>
                <v-img
                  v-if="item.isMoved"
                  style="position: absolute; top: 0; left: 0"
                  :src="
                    require(`@/assets/explore/menu/moved_${$_getLocale()}.png`)
                  "
                  @click="
                    movedDialog = true;
                    movedRefImg = item.movedRefImg;
                    movedMsg = item.movedMsg;
                  "
                ></v-img>
              </v-col>
              <v-col cols="4" v-for="i in 12 - items.length" :key="i + 7">
                <div
                  style="aspect-ratio: 1"
                  class="d-flex align-center justify-center"
                >
                  <v-icon color="cyan lighten-2">mdi-circle</v-icon>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-main>
      <SkipTut />
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    SkipTut: () =>
      import(
        /* webpackChunkName: "component-skip-tut" */ "@/components/tutorial/SkipTut"
      ),
    MovedDialog: () =>
      import(
        /* webpackChunkName: "component-moved-dialo" */ "@/components/MovedDialog"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
    tutorial: (state) => state.tutorial.data,
  }),
  props: [
    //
  ],
  data: () => ({
    intraction: null,
    items: [],
    movedDialog: false,
    movedRefImg: "",
    movedMsg: "",
  }),
  created() {
    //
  },
  mounted() {
    this.items = [
      {
        name: this.$t("title.shop"),
        page: "PageHomeShop",
        icon: require("@/assets/explore/menu/shop.png"),
        tutId: "ShopNow",
        isLock: false,
      },
      {
        name: this.$t("title.wardrobe"),
        page: "PageHomeWardrobe",
        icon: require("@/assets/explore/menu/wardrobe.png"),
        tutId: "tutWar",
        isLock: false,
      },
      {
        name: this.$t("string.collection"),
        page: "PageExploreCollections",
        icon: require("@/assets/explore/menu/collection.png"),
        tutId: "tutColl",
        isLock: false,
      },
      {
        name: this.$t("title.gallery"),
        page: "PageHomeGallery",
        icon: require("@/assets/explore/menu/gallery.png"),
        tutId: "tutGall",
        isLock: false,
      },
      {
        name: this.$t("title.studio"),
        page: "PageHomeStudio",
        icon: require("@/assets/explore/menu/studio.png"),
        tutId: "tutStd",
        isLock: false,
      },
      {
        name: this.$t("title.locksmith"),
        page: "PageHomeLocksmith",
        icon: require("@/assets/explore/menu/locksmith.png"),
        tutId: "tutLock",
        isLock: false,
      },
      // {
      //   name: this.$t("title.tower"),
      //   page: "PageExploreDailyGame",
      //   icon: require("@/assets/explore/menu/tower.png"),
      //   tutId: "tutTow",
      //   isLock: false,
      //   isMoved: true,
      //   movedRefImg: require("@/assets/explore/menu/towerMoved.png"),
      //   movedMsg: this.$t("string.movedMsg"),
      // },
    ];

    // #tutExploreShop - continue intro js ecplore Shop tutorial
    if (this.tutorial.Ongoing == "tutExploreShop") {
      let tempTut = this.$_.cloneDeep(this.tutorial);
      tempTut.Ongoing = "tutExploreShop";
      tempTut.Step = 3;
      this.$store.commit("updateTutorial", tempTut);

      this.intraction = "pointer-events: none;";
      setTimeout(() => {
        this.$intro().exit();
        this.$intro()
          .setOptions({
            exitOnOverlayClick: false,
            showButtons: false,
            showBullets: false,
            steps: [
              {
                element: document.querySelector("#ShopNow"),
                intro:
                  '<img src="' +
                  require("@/assets/tutorial/chat_2.png") +
                  '">' +
                  this.$t("tutorial.exploreShop.dialog_3"),
              },
            ],
          })
          .start();
        this.intraction = "pointer-events: all;";
        let audio = new Audio(require("@/assets/sounds/sfx_chat_2.mp3"));
        audio.volume = this.settings.audio.sfx * this.settings.audio.master;
        audio.play();
      }, 1000);
    }
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageExploreLanding",
      });
    },
  },
};
</script>

<style scoped>
.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(100, 255, 255, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>